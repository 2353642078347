import React, { useEffect } from 'react';

const RedirectWinstonSalemUnbouncePage: React.FC<any> = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace(`http://unbouncepages.com/fins-winstonsalem/`);
    }
  }, []);

  return <></>;
};

export default RedirectWinstonSalemUnbouncePage;
